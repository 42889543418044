<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3">
      <b-col class="button-wrapper">
        <div>
          <b-button variant="success" @click="exportAll" v-if="$can('export_release_point')">Export</b-button>
        </div>
        <div style="margin-right: 1rem" v-show="this.selected.length > 0">
          <b-button variant="primary" v-if="$can('approve_release_point')" @click="onAction('bulk-release', null)">Release Point</b-button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <b-form-group
         class="mb-3"
         label="Search"
         label-for="search"
         description="Searchable: Name, Email, Referral Code"
        >
          <b-input-group>
            <b-form-input
             id="search"
             type="search"
             v-model="filterText"
             placeholder="Name, Email, Referral Code"
             @keypress="doFilter"
             @keyup.delete="doFilter"
            ></b-form-input>
            <b-input-group-append>
              <b-button
               variant="secondary"
               type="button"
               @click="resetFilter"
              >
                reset
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <b-form-group
           class="mb-3"
           label="Date"
           label-for="dateRange">
            <b-input-group>
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <range-picker id="dateRange" :start="startDate" :end="endDate" @picker="doDateFilter"></range-picker>
              <b-input-group-append>
                <b-button type="button" variant="secondary" @click="resetDateFilter">Reset</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm>
        <div>
          <b-form-group
           class="mb-3"
           label="Search By Title"
           label-for="label-title"
          >
            <b-form-select id="label-status" :options="titleOptions" v-model="title" @change="doFilterTitle"></b-form-select>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <vuetable
     ref="vuetable"
     @vuetable:load-error="handleLoadError"
     :api-url="apiUrl"
     :http-options="HttpOptions"
     :fields="fields"
     pagination-path=""
     :muti-sort="true"
     :sort-order="sortOrder"
     :append-params="moreParams"
     @vuetable:pagination-data="onPaginationData"
    >
     <template slot="checkbox-slot" slot-scope="prop">
      <input v-show="prop.rowData.status_id === 0" type="checkbox" :value="prop.rowData.transaction_id" @click="onChangeChecked(prop.rowData.transaction_id)">
     </template>
     <template slot="date-slot" slot-scope="prop">
      <span>{{ prop.rowData.transaction_date }}</span>
     </template>
     <template slot="user-slot" slot-scope="prop">
      <router-link :to="onAction('view-user', prop.rowData, prop.rowIndex)" target="_blank">{{ prop.rowData.users.name }} - {{ prop.rowData.users.email }}</router-link>
     </template>
     <template slot="title-slot" slot-scope="prop">
      <span>{{ prop.rowData.title }}</span>
     </template>
     <template slot="invoice-slot" slot-scope="prop">
      <span>{{ prop.rowData.invoice_number}}</span>
     </template>
     <template slot="benefit-slot" slot-scope="prop">
      <span>{{ prop.rowData.benefit }}</span>
     </template>
     <template slot="status-slot" slot-scope="prop">
      <span v-if="prop.rowData.status_id === 0">{{ prop.rowData.status }}</span>
      <span v-else-if="prop.rowData.status_id === 1">{{ prop.rowData.status }}</span>
      <span @click="onAction('open-modal-reason', prop.rowData)" class="link-open-modal" v-else-if="prop.rowData.status_id === 2">{{ prop.rowData.status }}</span>
     </template>
     <template slot="disbrusement-slot" slot-scope="prop">
      <span>{{ prop.rowData.disbursement_date }}</span>
     </template>
     <template slot="actions-slot" slot-scope="prop">
      <div class="action-wrapper">
        <b-button v-show="prop.rowData.status_id === 0" variant="warning" v-if="$can('approve_release_point')" style="margin-bottom: .5rem;" @click="onAction('approve', prop.rowData)">Approve</b-button>
        <b-button v-show="prop.rowData.status_id === 0" variant="danger" v-if="$can('approve_release_point')" style="margin-bottom: .5rem;" @click="onAction('open-modal-reject', prop.rowData)">Reject</b-button>
      </div>
     </template>
    </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
      <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage"></vuetable-pagination>
    </div>
    <b-modal v-if="this.rejectId !== ''" size="lg" v-model="rejectModal" hide-footer="true" hide-header="true">
      <b-row class="mb-3">
        <b-col>
          <h5 class="text-center">Rejection Reason</h5>
          <br>
          <b-form-textarea
           id="textarea"
           v-model="rejectText"
           placeholder="Rejection Reason"
           rows="3"
          ></b-form-textarea>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="modal-action-wrapper">
            <b-button
             variant="secondary"
             @click="cancelReject"
            >
              Cancel
            </b-button>
            <b-button
             variant="primary"
             @click="submitReject"
            >
              Submit
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-model="reasonModal" size="lg" hide-footer hide-header>
      <b-row class="mb-3">
        <b-col>
          <h5 class="text-center">Rejection Reason</h5>
          <br>
          <p class="text-center">{{this.description}}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="modal-action-wrapper">
            <b-button
             variant="secondary"
             @click="closeModalReason"
            >Close</b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'

let startDate = ''
let endDate = ''
Vue.use(Vuetable)
export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo
  },
  prop: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  data() {
    return {
      startDate: '',
      endDate: '',
      selected: [],
      filterText: '',
      title: '',
      rejectModal: false,
      rejectId: '',
      rejectText: '',
      description: '',
      isLoading: false,
      reasonModal: false,
      userId: [],
      titleOptions: [
        {value: '', text: 'Choose Title'},
        {value: 0, text: 'Civillian'},
        {value: 1, text: 'Knight'},
        {value: 2, text: 'Knight Lieutenant'},
        {value: 3, text: 'Knight Captain'},
        {value: 4, text: 'Knight Commander'}
      ],
      errors: {
        code : '',
        message: '',
        status: ''
      },
      apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `army/release-point`,
      HttpOptions: {
        headers: {
          'Accept' : 'application/json',
          'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
        }
      },
      sortOrder: [
        {
          field: 'transaction_date',
          sortField: 'transaction_date',
          direction: 'desc'
        }
      ],
      moreParams: {},
      fields: [
        {
          name: 'checkbox-slot',
          titleClass: 'center aligned',
          dataClass: 'center aligned',
          title: '',
          width: '5%'
        },
        {
          name: 'date-slot',
          sortField: 'transaction_date',
          title: 'Transaction Date'
        },
        {
          name: 'user-slot',
          sortField: 'users.name',
          title: 'User'
        },
        {
          name: 'title-slot',
          sortField: 'title',
          title: 'Title'
        },
        {
          name: 'invoice-slot',
          title: 'Invoice No'
        },
        {
          name: 'benefit-slot',
          sortField: 'benefit',
          title: 'Benefit'
        },
        {
          name: 'status-slot',
          sortField: 'status',
          title: 'Status'
        },
        {
          name: 'disbrusement-slot',
          title: 'Disbrusement Date'
        },
        {
          name: 'actions-slot',
          title: 'Actions',
          titleClass: 'center aligned',
          dataClass: 'center aligned'
        }
      ]
    }
  },
  methods: {
    onChangeChecked(id) {
      let arr = this.selected.includes(id)
      if (arr !== true) {
        this.selected.push(id)
      }
    },
    onAction (action, data) {
      if (action == 'view-user') {
        return { name: 'Detail End User', params: { id: btoa(data.users.id)} }
      }else if (action == 'detail-item') {
        this.$router.push({ name: 'Release Point Detail', params: { id: data.transaction_id } })
      }else if (action == 'open-modal-reject') {
        this.rejectModal = true
        this.rejectId = data.transaction_id
      }else if (action == 'approve') {
        this.isLoading = true
        this.$http.post(`army/release-point/update`, {
          id: data.transaction_id,
          status: 1
        }).then(() => {
          this.isLoading = false
          this.$swal.fire(
            'Success!',
            'Release Point Berhasil Diapprove',
            'success'
          ).then(() => {
            location.reload()
          })
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status
            this.errors.message = error.response.data.meta.message
            this.errors.status = error.response.data.meta.code
            if (this.errors.status == 400) {
              this.$swal.fire(
                'Failed',
                this.errors.message,
                'error'
              )
            }else if (this.errors.status === 404) {
              this.$swal.fire(
                'Failed',
                this.errors.message,
                'error'
              ).then(() => {
                location.reload()
              })
            }
          }
        })
      }else if (action === 'bulk-release') {
        this.isLoading = true
        this.$http.post(`army/release-point/batch-update`, {
          ids: this.selected,
          status: 1
        }).then(() => {
          this.isLoading = false
          this.$swal.fire(
            'Success!',
            'Bulk Release Point Berhasil Diapprove',
            'success'
          ).then(() => {
            location.reload()
          })
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status
            this.errors.message = error.response.data.meta.message
            this.errors.status = error.response.data.meta.code
            if (this.errors.status === 400) {
              this.$swal.fire(
                'Failed!',
                this.errors.message,
                'error'
              )
            }else if (this.errors.status === 401) {
              this.$swal.fire(
                'Failed!',
                this.errors.message,
                'error'
              ).then(() => {
                location.reload()
              })
            }
          }
        })
      }else if (action === 'open-modal-reason') {
        this.reasonModal = true
        this.description = data.reason
      }
    },
    submitReject () {
      this.isLoading = true
      this.$http.post(`army/release-point/update`, {
        id: this.rejectId,
        status: 2,
        reason: this.rejectText
      }).then(() => {
        this.isLoading = false
        this.$swal.fire(
            'Success!',
            'Release Point Berhasil Direject!',
            'success'
          ).then(() => {
            this.rejectModal = false
            this.rejectId = ''
            this.rejectText = ''
            location.reload()
          })
      }).catch((error) => {
        if (error.response) {
          this.isLoading = false
          this.errors.code = error.response.status
          this.errors.message = error.response.data.meta.message
          this.errors.status = error.response.data.meta.code
          if (this.errors.status == 400) {
            this.$swal.fire(
              'Failed',
              this.errors.message,
              'error'
            ).then(() => {
              location.reload()
            })
          }else if (this.errors.status == 404) {
            this.$swal.fire(
              'Failed',
              this.errors.message,
              'error'
            ).then(() => {
              location.reload()
            })
          }
        }
      })
    },
    closeModalReason() {
      this.reasonModal = false
      this.description = ''
    },
    cancelReject () {
      this.rejectModal = false
      this.rejectId = ''
      this.rejectText = ''
    },
    doFilter () {
      this.$events.$emit('filter-set', this.filterText)
    },
    doFilterTitle () {
      this.$events.$emit('title-set', this.title)
    },
    resetFilter () {
      this.filterText = ''
      this.$events.$emit('filter-reset')
    },
    resetTitleFilter () {
      this.title = ''
      this.$events.$emit('title-reset')
    },
    handleLoadError(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem('access_token') != null) {
          localStorage.removeItem('access_token');
          this.$swal.fire(
            'Your session expired!',
            'Your session has expired. Please login again to access this page!',
            'error'
          ).then(() => {
            this.$router.push("/login")
          })
        }
      }else if(this.errors.code == 403) {
          this.$router.push("/403")
        }else if(this.errors.code == 500) {
          this.$router.push("/500")
        }
    },
    resetDateFilter () {
      this.startDate = '',
      this.endDate = '',
      this.title = '',
      this.$events.$emit('date-filter-reset')
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    onFilterSet(filterText) {
      this.moreParams = {
        'search': encodeURIComponent(filterText),
        'title': this.title,
        'start_from': this.startDate,
        'end_to': this.endDate
      }
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onTitleFilterSet() {
      if (this.title === '') {
        this.onFilterReset()
      }else{
        this.moreParams = {
          'title' : this.title,
          'search': encodeURIComponent(this.filterText),
          'start_from': this.startDate,
          'end_to': this.endDate
        }
        Vue.nextTick(() => this.$refs.vuetable.refresh())
      }
    },
    doDateFilter(value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
      this.$events.fire('date-set', [this.startDate, this.endDate])
    },
    onDateSet() {
      this.moreParams = {
        'start_from': this.startDate,
        'end_to': this.endDate,
        'title': this.title,
        'search': encodeURIComponent(this.filterText)
      }
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onFilterReset() {
      this.moreParams = {}
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    onTitleFilterReset() {
      this.moreParams = {}
      Vue.nextTick( () => this.$refs.vuetable.refresh())
    },
    onDateFilterReset () {
      this.moreParams = {}
      Vue.nextTick(() => this.$refs.vuetable.refresh())
    },
    exportAll () {
      this.isLoading = true
      const direction = this.$refs.vuetable.sortOrder[0].direction
      const field = this.$refs.vuetable.sortOrder[0].sortField
      const sortField = field + '|' + direction
      if (this.startDate != '' && this.endDate != '') {
        startDate = this.startDate
        endDate = this.endDate
      } else {
        endDate = this.$setDate.format('YYYY-MM-DD')
        startDate = this.$setDate.subtract(6, 'days').format('YYYY-MM-DD')
        this.startDate = startDate
        this.endDate = endDate
      }
      this.$http.get(`transaction/army-release-point?search=` + encodeURIComponent(this.filterText) + '&title=' + this.title + '&sort=' + sortField + '&start_from=' + startDate + `&end_to=` + endDate)
      .then((result) => {
        this.isLoading = false
        const exportPath = result.data.meta.data
        window.location.href = exportPath
      }).catch((error) => {
        if (error.response) {
          this.isLoading = false
          this.errors.code = error.response.status
          this.errors.status = error.response.data.meta.code
          this.errors.headers = error.response.headers
        }
      })
    }
  },
  mounted() {
    this.$events.$on('filter-set', eventData => this.onFilterSet(eventData))
    this.$events.$on('date-set', eventData => this.onDateSet(eventData))
    this.$events.$on('title-set', eventData => this.onTitleFilterSet(eventData))
    this.$events.$on('filter-reset', () => this.onFilterReset())
    this.$events.$on('date-filter-reset', () => this.onDateFilterReset())
    this.$events.$on('title-reset', () => this.onTitleFilterReset())
  }
}
</script>

<style>
.loading {
  position: fixed;
  z-index: 999;
  height: 2rem;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3) ;
}

.button-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  width: 320px;
}

.link-open-modal {
  color: #CB211F;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
}

.action-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-action-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
</style>
<style src='spinkit/scss/spinkit.scss' lang='scss'></style>
