<template>
  <div className="animated">
    <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i> Data Table Release Point
      </b-card-header>
      <b-card-body>
        <Release-point></Release-point>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import ReleasePoint from './Table.vue'
export default {
  name: 'release-point',
  components: {
    ReleasePoint
  }
}
</script>

<style>
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
</style>